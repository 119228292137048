<template>
  <div class="product">
    <nav-header />
    <div class="product-list-wrap">
      <h3 class="product-title">
        <div class="product-title-bg">
          <img src="../../assets/img/title_bg/introduce.png" alt="" />
        </div>
        <div class="product-title-name">套餐介绍</div>
        <div class="product-title-line" />
      </h3>
      <div class="product-list">
        <div class="product-wrap">
          <!-- <div class="product-item">
            <div class="item-head p0">
              <div class="price">
                <span class="price-bold">免费版</span>
              </div>
              <div class="desc">注册即可使用</div>
            </div>
            <div class="item-rights">
              <div class="item-rights-list" v-for="(freeItem, index) in free" :key="index">
                <div class="rights-text" :class="!freeItem.has ? 'item-has' : ''">{{ freeItem.name }}</div>
                <div class="rights-text" :class="!freeItem.has ? 'item-has' : ''">{{ freeItem.is }}</div>
              </div>
              <div class="item-btn b0" @click="toHome" data-text="立即体验">
                <span>立</span>
                <span>即</span>
                <span>体</span>
                <span>验</span>
              </div>
            </div>
          </div> -->
          <div
            v-for="(item, index) in productList"
            :key="item.price"
            class="product-item"
          >
            <!-- 商品头部 -->
            <div :class="['item-head', 'p' + (index + 1)]">
              <div class="price">
                <span class="price-x">¥ </span>
                <span class="price-bold">{{ item.showP }}</span>
                <span class="price-x"> /{{ item.description }} </span>
              </div>
              <div class="desc">{{ item.showDesc }}</div>
              <div class="is-hot" v-if="item.inRecommend"></div>
            </div>
            <!-- 商品权益 -->
            <div class="item-rights">
              <div
                v-for="(item1, index) in vips"
                :key="index"
                class="item-rights-list"
              >
                <div class="rights-text" :class="!item1.has ? 'item-has' : ''">
                  {{ item1.name }}
                </div>
                <div class="rights-text" :class="!item1.has ? 'item-has' : ''">
                  {{ item1.is }}
                </div>
              </div>
              <!-- <div :class="['item-btn', 'b' + index]" @click="buyVip(item)">
                立即体验
              </div> -->
              <div
                :class="['item-btn', 'b' + (index + 1)]"
                @click="buyVip(item)"
                data-text="立即体验"
              >
                <span>立</span>
                <span>即</span>
                <span>体</span>
                <span>验</span>
              </div>
            </div>
          </div>
        </div>
        <div class="product-mask" v-if="prodLoading">
          <div class="mask-container">
            <i class="mask-icon el-icon-loading"></i>
            <div class="mask-text">正在加载商品...</div>
          </div>
        </div>
      </div>
    </div>
    <copy-right-footer />
  </div>
</template>

<script>
import NavHeader from "../../components/NavHeader";
import CopyRightFooter from "../../components/CopyRightFooter";
import { hxsGet } from "../../apis/index";
export default {
  components: {
    NavHeader,
    CopyRightFooter
  },
  data() {
    return {
      free: [
        {
          name: "PDF与各格式互转",
          is: "有",
          has: true
        },
        {
          name: "下载文档大小",
          is: "2M",
          has: true
        },
        {
          name: "下载文档个数",
          is: "每日1个",
          has: true
        },
        {
          name: "文档保存期",
          is: "30天",
          has: true
        },
        {
          name: "尊享会员标识",
          is: "无",
          has: false
        },
        {
          name: "PDF在线转换转换客户端会员",
          is: "无",
          has: false
        }
      ],
      vips: [
        {
          name: "PDF与各格式互转",
          is: "有",
          has: true
        },
        {
          name: "下载文档大小",
          is: "无限制",
          has: true
        },
        {
          name: "下载文档个数",
          is: "无限制",
          has: true
        },
        {
          name: "文档保存期",
          is: "30天",
          has: true
        },
        {
          name: "尊享会员标识",
          is: "有",
          has: true
        }
        // ,
        // {
        //   name: "PDF在线转换转换客户端会员",
        //   is: "有",
        //   has: true
        // }
      ],
      productList: [],
      prodLoading: true
    };
  },
  created() {
    this.getPordList();
  },
  methods: {
    // 获取商品列表
    getPordList() {
      hxsGet(
        "doconline/open/pay/grade/option/list.html?qid=" + localStorage.getItem("qid")
      )
        .then((res) => {
          this.prodLoading = false;
          this.productList = res.data;
        })
        .catch((err) => {
          this.prodLoading = false;
          console.log(err);
        });
    },
    // 立即体验
    buyVip(item) {
      this.$router.push({
        name: "vip",
        query: {
          pid: item.itemId
        }
      });
      if (window._czc) {
        window._czc.push([
          "_trackEvent",
          "DFP在线转换",
          "套餐介绍",
          "立即体验按钮点击次数"
        ]);
      }
    },
    toHome() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.p0 {
  background: linear-gradient(90deg, #8ca6cc, #a1b4d5);
}
.p1 {
  background: linear-gradient(90deg, #04b2dc, #14cbe2);
}
.p2 {
  background: linear-gradient(90deg, #2bbd67, #32ce85);
}
.p3 {
  background: linear-gradient(90deg, #ff9e44, #ffb744);
}
.p4 {
  background: linear-gradient(90deg, #4886f7, #2aa7f9);
}
.b0 {
  background: linear-gradient(135deg, #91a9ce 0%, #9eb2d4 100%);
}
.b1 {
  background: linear-gradient(135deg, #08b8dd 0%, #13cae2 100%);
}
.b2 {
  background: linear-gradient(135deg, #2cc06d 0%, #32ce84 100%);
}
.b3 {
  background: linear-gradient(135deg, #ffa344 0%, #ffb544 100%);
}
.b4 {
  background: linear-gradient(135deg, #428df7 0%, #2ba6f9 100%);
}
.item-has {
  color: #bbbbbb !important;
}
.product {
  .product-list-wrap {
    width: 1200px;
    min-width: 1200px;
    padding-top: 50px;
    margin: 0 auto;
    box-sizing: border-box;
    .product-title {
      text-align: center;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #212121;
      position: relative;
      .product-title-name {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }
      .product-title-bg {
        width: 500px;
        height: 30px;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
      .product-title-line {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 44px;
        height: 6px;
        background: #0078f8;
        border-radius: 3px;
        bottom: -55px;
      }
    }
    .product-list {
      position: relative;
      margin-top: 90px;
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin-bottom: 100px;
      .product-wrap {
        display: flex;
        margin: 0 auto;
        .product-item {
          transition: all 0.5s;
          box-shadow: 0px 2px 30px 0px rgba(79, 102, 157, 0.14);
          position: relative;
          top: 0px;
          left: 0;
          margin-right: 25px;
          .item-head {
            position: relative;
            width: 280px;
            height: 120px;
            color: #fff;
            // background: linear-gradient(90deg, #8ca6cc, #a1b4d5);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            text-align: center;
            padding-top: 28px;
            box-sizing: border-box;
            font-family: Microsoft YaHei;
            .price {
              margin-bottom: 15px;
              .price-bold {
                font-size: 28px;
                font-weight: bold;
              }
              .price-x {
                font-size: 14px;
              }
            }
            .desc {
              font-size: 14px;
              font-weight: 400;
            }
            .is-hot {
              width: 56px;
              height: 56px;
              background: url("../../assets/img/vip/hot.png") no-repeat;
              background-size: 100% 100%;
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 0 5px 0 0;
            }
          }
          .item-rights {
            border: 1px solid #e7eaf1;
            border-top: none;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 28px 15px 20px 15px;
            box-sizing: border-box;
            :nth-child(5) {
              display: none;
            }
            .item-rights-list {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 25px;
              .rights-text {
                font-size: 13px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #555555;
              }
            }
            .item-btn {
              width: 126px;
              height: 38px;
              // background: linear-gradient(135deg, #91a9ce 0%, #9eb2d4 100%);
              border-radius: 19px;
              margin: 0 auto;
              text-align: center;
              line-height: 38px;
              color: #fff;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              cursor: pointer;
              position: relative;
              z-index: 1;
              overflow: hidden;
              margin-top: 30px;
            }
            .item-btn:focus {
              outline: 0;
            }
            .item-btn > span {
              vertical-align: middle;
              display: inline-block;
              opacity: 0;
              color: #fff;
              -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
              -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
              transition: transform 0.3s, opacity 0.3s;
            }
            .item-btn::before {
              /* content: attr(data-text); */
              content: attr(data-text);
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              color: #fff;
              -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
              transition: transform 0.3s, opacity 0.3s;
            }
            .item-btn:hover {
              background-color: #96b7c9;
            }
            .item-btn:hover::before {
              opacity: 0;
              -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
            }
            .item-btn:hover > span {
              opacity: 1;
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
            }
            .item-btn:hover > span:nth-child(1) {
              -webkit-transition-delay: 0.045s;
              transition-delay: 0.045s;
            }
            .item-btn:hover > span:nth-child(2) {
              -webkit-transition-delay: 0.09s;
              transition-delay: 0.09s;
            }
            .item-btn:hover > span:nth-child(3) {
              -webkit-transition-delay: 0.135s;
              transition-delay: 0.135s;
            }
            .item-btn:hover > span:nth-child(4) {
              -webkit-transition-delay: 0.18s;
              transition-delay: 0.18s;
            }
          }
        }
      }
      .product-mask {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        z-index: 888;
        .mask-container {
          // width: 50px;
          // height: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          .mask-icon {
            font-size: 22px;
            margin-bottom: 10px;
            text-align: center;
            color: #0078f8;
          }
          .mask-text {
            text-align: center;
            font-size: 14px;
            color: #0078f8;
          }
        }
      }
      .product-item:hover {
        transform: translate3d(0, -10px, 0);
      }
      .product-item:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
